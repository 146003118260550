import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import DelegationProgram from "./pages/DelegationProgram";
import LiquidityPools from "./pages/LiquidityPools";
import Donate from "./pages/Donate";
import "../src/assets/scss/main.scss";
import TermsCondition from "./pages/TermsCondition";
import ServerProgram from "./pages/ServerProgram";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/delegation-program" element={<DelegationProgram />} />
        <Route path="/liquidity-pools" element={<LiquidityPools />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/server-program" element={<ServerProgram />} />

        {/* CATCH ALL */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
