import React from "react";
import { Link } from "react-router-dom";
import Banner from "../assets/images/banner/Hone-01.png";
function Home() {
  return (
    <>
      <section className="banner">
        <div className="wrapper container">
          <div className="flex-home">
            <h1>
              Terra <br />
              Classic <br />
              Foundation
            </h1>
            <p>
              Terra Classic Foundation is a non-profit foundation based in
              Estonia, dedicated to the decentralisation, adoption, and security
              of the Terra Classic ecosystem.{" "}
            </p>
            <Link to="/about">Learn More </Link>
          </div>
          <div className="flex-home flex-img">
            <img src={Banner} alt="" />
          </div>
        </div>
      </section>
      <section className="about grey">
        <div className="wrapper container">
          <div className="column-wrap">
            <div className="column col-del">
              <h2>
                Delegation <br />
                Program
              </h2>
              <div className="copy-wrap-dl">
                <p className="copy-delegation">
                  Validators can participate in the Terra Classic Foundation
                  Delegation Program if they meet certain performance
                  requirements. Foundation Delegation participants are eligible
                  to receive a delegation from the Terra Classic Foundation,
                  helping decentralize the network.{" "}
                </p>
                <Link to="/delegation-program"> Apply Now </Link>
              </div>
            </div>
            <div className="column col-liq">
              <h2>
                {" "}
                Liquidity <br />
                Program
              </h2>
              <div className="copy-wrap-dl">
                <p>
                  The Terra Classic Foundation Liquidity Program is focused on
                  providing liquidity to pools on decentralised exchanges to
                  further the adoption of the Terra Classic Network.
                </p>
                <Link to="/liquidity-pools"> View Liquidity Pools</Link>
              </div>
            </div>
          </div>
          <div className="column-wrap column-wrap-2">
            <div className="column col-del">
              <h2>
                Server <br /> Program
              </h2>
              <div className="copy-wrap-dl">
                <p className="copy-delegation">
                  Get private access to a full archive node at discounted prices with month to month contracts.{" "}
                </p>
                <Link to="/server-program">Get Access</Link>
              </div>
            </div>
          </div>
          <div className="donate">
            <h2>Donate</h2>
            <p className="copy-donate">
              Help Terra Classic Foundation scale up and support our mission by
              making a donation.{" "}
            </p>
            <Link to="/donate">Donate Now</Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
