import React from "react";

function TermsCondition() {
  return (
    <div className='container'>
      <div className='terms-c'>
        <h2 className='donate-copy-header'>Donor Terms & Conditions</h2>
        <ul>
          <li>
            The Donor represents and warrants that it is in compliance and will
            continue to be in compliance with all applicable anti-money
            laundering laws and regulations, in its respective country,
            including the Money Laundering and Terrorist Financing Prevention
            Act (RahaPTS) and any recommendations as provided from time to time
            by the Financial Action Task Force (FATF). Donor also hereby agrees
            to comply with any new or additional domestic or international
            anti-money laundering laws or regulations.
          </li>
          <li>
            Donor understands and acknowledges that Mittetulundusühing Terra
            Classic is, or may in the future become, subject to money laundering
            statutes, regulations and conventions of the Estonian or other
            international jurisdictions, and Donor agrees to execute
            instruments, provide information, or perform any other acts as may
            reasonably be requested by Mittetulundusühing Terra Classic for the
            purpose of carrying out due diligence as may be required by
            Applicable Law. Donor agrees that it will provide the
            Mittetulundusühing Terra Classic with such information as may be
            reasonably required to comply with applicable anti-money laundering
            laws or regulations. Donor understands, acknowledges and agrees that
            to the extent permitted by Applicable Law, any Mittetulundusühing
            Terra Classic may provide information, including confidential
            information, to the Estonian Financial Intelligence Unit or any
            other agency or instrumentality of the Estonian Government, or as
            otherwise required by Applicable Law, in connection with a request
            for information on behalf of an Estonian law enforcement agency
            investigating terrorist activity or money laundering.
          </li>
          <li>
            To the best of Donor’s knowledge, none of Donor, any person
            controlling or controlled by Donor, any person having a beneficial
            interest in Donor, or any person for whom Donor acts as agent or
            nominee in connection herewith is: (i) an individual or entity,
            country or territory, that is named on a list issued by the U.S.
            Department of the Treasury’s Office of Foreign Assets Control
            (“OFAC”), or an individual or entity that resides, is organized or
            chartered, or has a place of business, in a country or territory
            subject to OFAC’s various sanctions/embargo programs; (ii) a
            resident in, or organized or chartered under the laws of (A) a
            jurisdiction that has been designated by the Secretary of the
            Treasury under the USA PATRIOT Act as warranting special measures
            and/or as being of primary money laundering concern, or (B) a
            jurisdiction that has been designated as non-cooperative with
            international anti-money laundering principles by a multinational or
            inter-governmental group such as the Financial Action Task Force on
            Money Laundering (“FATF”) of which Estonia and the United States is
            a member; (iii) a financial institution that has been designated by
            the Secretary of the Treasury as warranting special measures and/or
            as being of primary money laundering concern; (iv) a “senior foreign
            political figure,” or any “immediate family” member or “close
            associate” of a senior foreign political figure, in each case within
            the meaning of Section 5318(i) of Title 31 of the United States Code
            or regulations issued thereunder; or (v) a prohibited “foreign shell
            bank” as defined in Section 5318(j) of Title 31 of the United States
            Code or regulations issued thereunder, or a U.S. financial
            institution that has established, maintains, administers or manages
            an account in the U.S. for, or on behalf of, a prohibited “foreign
            shell bank.”
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TermsCondition;
