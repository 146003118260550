import React from "react";
import { Link } from "react-router-dom";
// import LogoImage from "../../assets/images/TCF logo_Website@3x.png";
// import LogoImage1 from "../../assets/images/TCFNewLogo.png";
import LogoImage1 from "../../assets/images/footer-new-tcf.png";

function Footer() {
  return (
    <footer>
      <div className='wrapper container'>
        <div className='col'>
          {/* <h3 className="logo">Terra <br /> Classic <br /> Foundation</h3>
           */}
          <Link to='/' className='brand'>
            {/* <span>Terra</span><br />
                    <span>Classic</span><br />
                    <span>Foundation</span> */}
            <img src={LogoImage1} alt='' />
          </Link>
        </div>
        <div className='col'>
          <h3 className='head'>Community</h3>
          <div>
            <Link
              to='https://commonwealth.im/terra-classic/discussions'
              target='_blank'
            >
              Forums
            </Link>{" "}
            <br />
            <Link to='https://twitter.com/TCF_Terra' target='_blank'>
              Twitter
            </Link>{" "}
            <br />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
