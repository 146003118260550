import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoImage from "../../assets/images/TCF@3x.png";
import LogoImage1 from "../../assets/images/TCFNewLogo.png";

function Header() {
  const location = useLocation();
  const slug = location.pathname.replace(/\//g, "");
  const [isNavActive, setisNavActive] = useState(false);

  const handleHamburgerClick = () => {
    setisNavActive((state) => !state);
  };

  return (
    <header>
      <nav id="navbar" className="header-main container">
        <Link to="/" className="brand">
          {/* <span>Terra</span><br />
                    <span>Classic</span><br />
                    <span>Foundation</span> */}
          <img src={LogoImage1} alt="" />
        </Link>
        <ul className={isNavActive ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className={slug == "" ? "nav-item active" : "nav-item"}
              onClick={() => setisNavActive(false)}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about"
              className={slug == "about" ? "nav-item active" : "nav-item"}
              onClick={() => setisNavActive(false)}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/delegation-program"
              className={
                slug == "delegation-program" ? "nav-item active" : "nav-item"
              }
              onClick={() => setisNavActive(false)}
            >
              Delegation
              <br />
              Program
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/liquidity-pools"
              className={
                slug == "liquidity-pools" ? "nav-item active" : "nav-item"
              }
              onClick={() => setisNavActive(false)}
            >
              Liquidity
              <br />
              Pools
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/server-program"
              className={
                slug == "server-program" ? "nav-item active" : "nav-item"
              }
              onClick={() => setisNavActive(false)}
            >
              Server
              <br />
              Program
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/donate"
              className={slug == "donate" ? "nav-item active" : "nav-item"}
              onClick={() => setisNavActive(false)}
            >
              Donate
            </Link>
          </li>
        </ul>
        <div
          className={
            isNavActive ? "hamburger hidden-lg active" : "hamburger hidden-lg"
          }
          onClick={handleHamburgerClick}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
    </header>
  );
}

export default Header;
