import React from "react";
import Banner from '../assets/images/banner/about-01.png'
function About() {
    return (
        <div>
            <section className="banner">
                <div className="wrapper container">
                    <div className="flex-home">
                        <h2>About Us</h2>
                        <p>
                            Our mission is to help rebuild the Terra Classic protocol into the most inclusive and censorship resistant network in the world.
                        </p>
                    </div>
                    <div className="flex-img">
                        <img src={Banner} alt="" />
                    </div>
                </div>
            </section>

            <section className="about1 grey1">
                <div className="wrapper container">
                    <h2>What is TCF?</h2>
                    <div className="column-wrap">
                        <div className="column">
                            <p>
                                Terra Classic Foundation (TCF) is a non-profit organization registered in
                                Estonia as an MTU (Mittetulundusühing) (Registry Code:
                                80618607), and is governed as per the provisions of the
                                Non-Profit Association Act.
                            </p>
                            <p>
                                Our mission is to help rebuild the Terra Classic protocol
                                into the most inclusive and censorship resistant network in the world.
                            </p>
                        </div>
                        <div className="column">
                            <p>
                                Our expertise in policy making helps us champion the rights of
                                the community and token holders of Terra Classic.
                            </p>
                            <p>
                                Starting out with proposals 4080 (Distribute 50% Transaction Fee
                                to Community Pool) and 4095 (Re-enabling Staking), TCF has now
                                evolved into a formal organization to create a meaningful and
                                lasting impact to the Terra Classic community.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="description">
                <div className="wrapper container">
                    <div className="title">
                        <h2>Why does TCF exist?</h2>
                        <p>
                            We exist to find solutions to 5 core issues currently faced by the
                            Terra Classic community.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Oversupply</h2>
                        <p>
                            With a current supply of 6.83T LUNC, an oversupply of tokens
                            remains a core issue of the community. The supply overhang of LUNC
                            and USTC is a major issue that TCF seeks to tackle.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Lack of Demand</h2>
                        <p>
                            UST has been at the epicenter of Terra’s growth, with LUNA-UST
                            swaps driving the majority utility and revenue on-chain. The
                            de-pegging of UST and the subsequent disabling of swaps has caused
                            both demand and revenue to drop significantly. Driving utility and
                            demand for Terra Classic is a key focus area for TCF.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>On-chain Volume</h2>
                        <p>
                            Prior to the de-pegging, most of the LUNA and UST tokens had
                            shifted from on-chain to exchanges. The current staking ratio of
                            Terra Classic stands at ~15%, which indicates that most of the
                            tokens (~85%) still lie off-chain. Improving Terra Classic’s
                            staking ratio remains a key priority of TCF.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Developer Funding </h2>
                        <p>
                            With TFL abandoning the Terra Classic blockchain, the need for
                            developer funding to maintain the chain has become a core issue.
                            TCF aims to tackle this issue by ensuring that the Community Pool
                            is sufficiently funded for development teams on Terra Classic.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Staking Rewards</h2>
                        <p>
                            Stakers are rewarded from two sources: The Oracle Pool and gas
                            fees. Due to market swaps being disabled, the Oracle Pool has
                            become a depleting source, lowering staking rewards. On the other
                            hand, low gas fees and low on-chain volume does not generate
                            adequate rewards for stakers. Increasing rewards for stakers from
                            the Oracle Pool as well as gas fees is a core priority for TCF.
                        </p>
                    </div>
                </div>
            </section>
            <section className="description grey1">
                <div className="wrapper container">
                    <div className="title">
                        <h2>Who funds TCF?</h2>
                        <p>
                            TCF is funded by private individuals and organizations who share
                            our values of keeping Terra Classic decentralized - We seek to
                            raise funds from enthusiasts in various fields: technology, law,
                            journalism, marketing, design, policy, etc. and aim to use those
                            funds to promote and advocate decentralized governance on Terra
                            Classic.
                        </p>
                    </div>
                </div>
            </section>
            <section className="description">
                <div className="wrapper container">
                    <div className="title">
                        <h2>How does TCF work?</h2>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Strategy comes first</h2>
                        <p>
                            At TCF our focus lies on strategic thinking. We base our work on
                            research and insights towards outcomes that are clearly mapped
                            towards policy goals.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Bridging public and policy divide</h2>
                        <p>
                            At TCF we hope to bridge the gap between the community,
                            validators, delegates, L1 developers, dapps, public infrastructure providers and third parties
                            (CEX, wallets, etc). Based on calculated risks, we take a stand
                            and follow a path of advocacy towards outcomes. We hope to
                            accelerate these efforts so the entire ecosystem is benefitted.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Building communities for public victories</h2>
                        <p>
                            At TCF we believe that an organizational backbone is pivotal in building 
                            a membership base as well as to ensure that a growing community endures 
                            over time. As the Latin saying goes “Vires in Numeris”, there is strength 
                            in numbers and as more users join the Terra Classic ecosystem, we need to 
                            ensure that their rights are advocated. By organizing over time, we aim to 
                            grow a cadre of community leaders that champion the rights of the community.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Campaigning for proposals </h2>
                        <p>
                            With 9 successful proposals and counting, we are no strangers to
                            drafting proposals and campaigning for them. We work alongside
                            different teams and member groups to ensure that our proposals are
                            implemented.
                        </p>
                    </div>
                    <div className="copy">
                        <hr />
                        <h2>Channeling advocacy into outcomes</h2>
                        <p>
                            Most of our work involves understanding the Terra Classic
                            blockchain and ecosystem. We engage with various stakeholders to
                            understand their issues and pain points so that we can work
                            towards finding effective solutions through innovative ideas and
                            thinking.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
