// import React from "react";
import React, { useState } from "react";
import Banner from "../assets/images/banner/liquidity pools.png";

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <button className="accordion-title" onClick={toggleAccordion}>
        {title}
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

function ServerProgram() {
  return (
    <div className="server-program">
      <section className="banner">
        <div className="wrapper container">
          <div className="flex-home">
            <h2>
              Server <br /> Program
            </h2>
            <p>
              Get private RPC access to a full archive node at discounted
              prices. This program gives select Terra Classic ecosystem
              participants private access to hardware that is usually difficult
              to setup and costly to maintain.
            </p>
          </div>
          <div className="flex-img">
            <img src={Banner} alt="" />
          </div>
        </div>
      </section>
      <section className="faq ">
        <div className="about-wrapper container">
          <h4 className="about-head">
            About this
            <br /> program
          </h4>
          <div className="about-copy">
            <p className="copy ">
              RPC access is a key component of a network’s operations. However,
              getting RPC access to full archive nodes may be difficult for some
              individuals given the cost required to setup and run a full
              archive node. For that reason, the Terra Classic Foundation,
              through its data center partner, has setup a private full archive
              node with blocks that go all the way back to the columbus-5
              genesis height. Private RPC access to this node is being offered
              to select Terra Classic ecosystem participants at discounted
              prices, with month-to-month contracts.
            </p>
            <p className="copy">
              This program is designed to foster sustainable growth in the RPC
              community and further decentralize the Terra Classic network.
            </p>
          </div>
        </div>
        <div className="faq-wrapper container">
          <h4 className="faq-head">FAQs</h4>
          <div className="faq-item">
            <div className="faq-copy-wrapper">
              <div className="qt-ans">
                <p className="qt">
                  What is the oldest block on the archive node?
                </p>
                <p className="ans">
                  The oldest block on the archive node goes all the way back to
                  height 4724001, which is the genesis block height on
                  columbus-5.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">
                  Who is responsible for maintaining the archive node?
                </p>
                <p className="ans">
                  Terra Classic Foundation has deployed the full archive node
                  with its data center partner. TCF along with its data center
                  partner will be looking after the ongoing maintenance of the
                  node. This includes securing the node, and responding to any
                  exploits, abuses, or other behaviors that are prohibited under
                  the data center partner's SLA.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">Who is the data center partner?</p>
                <p className="ans">
                  Terra Classic Foundation has partnerned with{" "}
                  <a
                    className="link-a"
                    href="https://www.hexxagon.io/"
                    target="_blank"
                  >
                    Hexxagon
                  </a>{" "}
                  to bring low-cost RPC access to the Terra Classic network. We
                  may engage with more partners in the future.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">What are the server specs?</p>
                <p className="ans">
                  The archive node has been spec’d to meet the needs of the
                  network with a long term view. The following specs should be
                  sufficient for seamless access to the node.
                </p>
              </div>
            </div>
            <div className="accordion-wrapper">
              <AccordionItem title="Hexxagon Kubernetes Clustered Virtual Environment">
                <div className="faq-acc-ans">
                  <div className="flex-class">
                    <div className="flex-item">CPU</div>
                    <div className="flex-item1">Intel Xeon Gold 6154</div>
                  </div>
                  <div className="flex-class">
                    <div className="flex-item">Cores</div>
                    <div className="flex-item1">up to 20 vCPUs</div>
                  </div>
                  <div className="flex-class">
                    <div className="flex-item">Storage</div>
                    <div className="   flex-item1">
                      NVMe clustered storage, 5TB quota
                    </div>
                  </div>
                  <div className="flex-class">
                    <div className="flex-item">Memory</div>
                    <div className="   flex-item1">64 GB</div>
                  </div>
                </div>
              </AccordionItem>
            </div>
            <div className="faq-copy-wrapper">
              <div className="qt-ans">
                <p className="qt">
                  What is the pricing for this private RPC access?
                </p>
                <p className="ans">
                  The exact pricing is determined on a case to case basis,
                  however, the RPC access is offered at a highly subsidised rate
                  from the prevailing market price in order to faciliate
                  adoption of the Terra Classic network.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">Are there any commitments?</p>
                <p className="ans">
                  The special pricing is only available with a minimum
                  commitment of one month.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">
                  Does the Terra Classic Foundation profit from this program?
                </p>
                <p className="ans">
                  No. Terra Classic Foundation is a non-profit organisation that
                  is committed to its mission. The cost subsidy offered through
                  this program is funded from donations received by the
                  foundation.
                </p>
              </div>
              <div className="qt-ans">
                <p className="qt">How do I sign up and get access?</p>
                <p className="ans">
                  To sign up and get private access to the archive node, DM us
                  on{" "}
                  <a
                    className="link-a"
                    href="https://x.com/TCF_Terra"
                    target="_blank"
                  >
                    X
                  </a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServerProgram;
