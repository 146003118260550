import React, { useState, useEffect } from "react";
import Banner from "../assets/images/banner/delegation program.png";
import cardOne from "../assets/images/Impact/01.png";
import cardtwo from "../assets/images/Impact/02.png";
import cardthree from "../assets/images/Impact/03.png";
// import DVcardimg from "../assets/images/Impact/logo.png";
// import StakebinLogoBlue from "../assets/images/greyLogo/stakebin-Blue.png";
// import StakebinLogoWhite from "../assets/images/greyLogo/stakebin-grey.png";
// // import StakebinLogo from "../assets/images/logos/stakebin.png";
// import DelightLogoBlue from "../assets/images/greyLogo/Delight-blue.png";
// import DelightLogoWhite from "../assets/images/greyLogo/Delight-grey.png";
// import TerrariumBlue from "../assets/images/greyLogo/Terrarium-blue.png";
// import TerrariumWhite from "../assets/images/greyLogo/Terrarium-grey.png";
// import HexxagonBlue from "../assets/images/greyLogo/hexxagon-blue.png";
// import HexxagonWhite from "../assets/images/greyLogo/hexxagon-grey.png";
// import LuncBlue from "../assets/images/greyLogo/Lunc.png";
// import LuncGray from "../assets/images/greyLogo/Lunc-grey.png";
// import StakeSystemBlue from "../assets/images/greyLogo/stake-blue.png";
// import StakeSystemwhite from "../assets/images/greyLogo/stake-grey.png";
// import VegasNonde from "../assets/images/greyLogo/Vegas-blue.png";
// import VegasOld from "../assets/images/greyLogo/Vegas-grey.png";
// import TCBBlue from "../assets/images/greyLogo/TCB-blue.png";
// import TCBGray from "../assets/images/greyLogo/TCB-grey.png";
// import TerraportBlue from "../assets/images/greyLogo/terraport-blue.png";
// import TerraportGray from "../assets/images/greyLogo/terraport-grey.png";
// import BurnItAllBlue from "../assets/images/greyLogo/Burn it all.png";
// import BurnItAllGray from "../assets/images/greyLogo/Burn it all grey.png";
// import NodeOpsBlue from "../assets/images/greyLogo/nodeops-blue.png";
// import NodeOpsGray from "../assets/images/greyLogo/nodeops-grey.png";
import BoxOne from "../assets/images/DelegationCriteria/02.png";
import BoxTwo from "../assets/images/DelegationCriteria/03.png";
import BoxThree from "../assets/images/DelegationCriteria/01.png";
import AboutOne from "../assets/images/about/01.png";
import AboutTwo from "../assets/images/about/02.png";

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const AccordionItem = ({ title, children, isOpen, onToggle }) => {
  //   const [isOpen, setIsOpen] = useState(isFirst);

  //   const toggleAccordion = () => {
  //     setIsOpen(!isOpen);
  //   };

  return (
    <div className="accordion-item">
      <button className="accordion-title" onClick={onToggle}>
        {title}
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

// import React, { useState } from "react";

// const HoverCard = ({
//   numPlate,
//   link,
//   imgDefault,
//   imgHover,
//   altText,
//   cardText,
// }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div
//       className="dv-card"
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       {/* <div className="num-plate">{numPlate}</div> */}
//       <a href={link} target="_blank" rel="noopener noreferrer">
//         <img
//           className="card-img"
//           src={isHovered ? imgDefault : imgHover}
//           alt={altText}
//         />
//       </a>
//       <p className="card-copy">{cardText}</p>
//     </div>
//   );
// };

function DelegationProgram() {
  const [openIndex, setOpenIndex] = useState(0); // Initially open the first item
  const [delegatedAmount, setDelegatedAmount] = useState(0);
  const [validatorCount, setValidatorCount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch delegated amount and validator count
        const response = await fetch(
          "https://terra-classic-lcd.publicnode.com/cosmos/staking/v1beta1/delegations/terra14rz46hp3rg5tvshdsjl5mmtcr9s7cputfpacwm"
        );
        const data = await response.json();

        // Calculating total delegated amount
        let totalDelegatedAmount = 0;
        data.delegation_responses.forEach((delegation) => {
          totalDelegatedAmount +=
            parseFloat(delegation.balance.amount) / 1000000;
        });

        //representing in billion
        let totalDelegatedAmount_in_Billion = totalDelegatedAmount / 1000000000;
        setDelegatedAmount(totalDelegatedAmount_in_Billion.toFixed());
        setValidatorCount(data.pagination.total);

        // Fetch Coingecko API
        const options = {
          method: "GET",
          headers: {
            accept: "application/json",
            "x-cg-demo-api-key": "CG-eix8oVbeDRTzdGnZExm3hd1U",
          },
        };

        const luncPriceResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=terra-luna&vs_currencies=usd",
          options
        );
        const luncPriceData = await luncPriceResponse.json();
        const luncPriceUSD = luncPriceData["terra-luna"].usd;

        // Calculate total value
        const totalValueUSD = totalDelegatedAmount * luncPriceUSD;

        //representing in 1000's or k
        let totalValueUSD_in_k = totalValueUSD / 1000;
        setTotalValue(totalValueUSD_in_k.toFixed());
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div>
      <section className="banner dlp-banner">
        <div className="wrapper container">
          <div className="flex-home">
            <h2>
              Delegation <br /> program
            </h2>
            <p>
              The Terra Classic Foundation Delegation Program aims to further
              diversify the network, recognize contributors, and give
              independent validators a boost.
            </p>
          </div>
          <div className="flex-img">
            <img src={Banner} alt="" />
          </div>
        </div>
      </section>
      <section className="floating-card">
        <div className="fc-wrapper container">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="fc-card">
                <img className="" src={cardOne} alt="cardone" />
                <h3 className="numbers">{delegatedAmount}B</h3>
                <p className="copy">
                  Lunc <br /> delegated
                </p>
              </div>
              <div className="fc-card">
                <img className="" src={cardtwo} alt="cardtwo" />
                <h3 className="numbers">${totalValue}k</h3>
                <p className="copy">
                  total <br /> value
                </p>
              </div>
              <div className="fc-card">
                <img className="" src={cardthree} alt="cardthree" />
                <h3 className="numbers">{validatorCount}</h3>
                <p className="copy">validators</p>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="about-program">
        <div className="ap-wrapper container">
          <img src={AboutOne} className="about-one" alt="aboutone" />
          <img src={AboutTwo} className="about-two" alt="aboutone" />
          <h3 className="abt-heading">About Program</h3>
          <p className="abt-copy">
            In true proof-of-stake fashion, the success of the Terra Classic
            network is dependent on building a vibrant, and resilient validator
            ecosystem. Validators play an integral role in ensuring the health,
            performance, and security of the network. To support the growth of
            this community and further decentralize the protocol, we’re excited
            to announce The Terra Classic Foundation Delegation Program.
          </p>
          <p className="abt-copy">
            This program is designed to strategically delegate the Foundation’s
            tokens to a select group of validators who are active, and are
            making significant contributions to the ecosystem. Validators can
            apply by sending a DM to us on X to receive stake delegations for
            the columbus-5 mainnet.
          </p>
        </div>
      </section>
      <section className="delegation-criteria">
        <h3 className="dc-heading container">delegation criteria</h3>
        <div className="dc-wrapper container">
          <div className="wrapper-item">
            <p className="dc-copy">
              When delegating the Foundation's tokens, there are a few common
              areas that we always looks for.
            </p>
            <div className="img-wrap">
              <img className="img-1" src={BoxOne} alt="boxone" />
              <img className="img-2" src={BoxTwo} alt="boxone" />
              <img className="img-3" src={BoxThree} alt="boxone" />
            </div>
          </div>
          <div className="wrapper-accordion">
            <div className="accordion-wrapper">
              <AccordionItem
                isOpen={openIndex === 0}
                onToggle={() => handleToggle(0)}
                title="Public Good"
              >
                <div className="faq-acc-ans">
                  We typically consider validators who either make a significant
                  open-source contribution to the Terra Classic ecosystem, or if
                  they have a meaningful free community offering. Depending on
                  the circumstances, we may also consider validators who have a
                  novel proof of concept to qualify. Validators operating
                  strictly for commercial purposes are generally not considered
                  under this criteria, however they can be considered under
                  other criteria listed below.
                </div>
              </AccordionItem>
              <AccordionItem
                isOpen={openIndex === 1}
                onToggle={() => handleToggle(1)}
                title="Performance"
              >
                <div className="faq-acc-ans">
                  In order to receive delegation from TCF, validators must prove
                  their ability to successfully sign blocks and oracle votes on
                  the Terra Classic mainnet. A 97% sign rate for blocks and
                  oracle votes will be considered as the minimum acceptance
                  criteria for receiving the Foundation's delegation.
                </div>
              </AccordionItem>
              <AccordionItem
                isOpen={openIndex === 2}
                onToggle={() => handleToggle(2)}
                title="Decentralisation"
              >
                <div className="faq-acc-ans">
                  In our endeavour to increase the Nakamoto index and further
                  decentralise the Terra Classic network, we typically delegate
                  to validators that do not cumulatively have more than 33.33%
                  share of the network. Validators that cumulatively hold more
                  than 33.33% of the network do not qualify under this criteria
                  but may be considered under other critera.
                </div>
              </AccordionItem>
              <AccordionItem
                isOpen={openIndex === 3}
                onToggle={() => handleToggle(3)}
                title="Governance"
              >
                <div className="faq-acc-ans">
                  Given that Terra Classic is a 100% community owned chain, a
                  record of partipation in governance proposals is a critical
                  factor for determining the Foundation's delegation. Validators
                  that do not partipate in governance proposals may not qualify
                  under this criteria but may be considered under other
                  criteria.
                </div>
              </AccordionItem>
            </div>
          </div>
        </div>
      </section>
      <section className="delegated-validators">
        <div className="dv-wrapper container">
          <div className="dv-header">
            <h3 className="dv-heading">Delegated Validators</h3>
            <p>
              The following validators have received delegations from Terra
              Classic Foundation:
            </p>
          </div>
          <div className="dv-card-section">
            <a
              className="dv-card"
              href="https://terraclassic.stakebin.io/terra/supply"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">01</div> */}
              <div className="stakebin"></div>
              <p className="card-copy">Stakebin</p>
            </a>

            <a
              className="dv-card"
              href="https://www.delightlabs.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">02</div> */}
              <div className="delight"></div>
              <p className="card-copy">Delight Labs</p>
            </a>

            <a
              className="dv-card"
              href="https://www.terrarium.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">03</div> */}
              <div className="terrarium"></div>
              <p className="card-copy">Terrarium</p>
            </a>

            <a
              className="dv-card"
              href="https://terraport.finance/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">04</div> */}
              <div className="terraport"></div>

              <p className="card-copy">Terraport</p>
            </a>

            <a
              className="dv-card"
              href="https://www.nodeops.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">05</div> */}
              <div className="nodeops"></div>
              <p className="card-copy">NodeOps</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/burn-it-all"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">06</div> */}
              <div className="burnit-all"></div>
              <p className="card-copy">Burn It All</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/tcb"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">07</div> */}
              <div className="tcb"></div>
              <p className="card-copy">TCB</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/vegas-node"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">08</div> */}
              <div className="vegas"></div>
              <p className="card-copy">Vegas Node</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/lunc-goblins"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">09</div> */}
              <div className="lunc"></div>
              <p className="card-copy">Lunc Goblins</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/stake-systems"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">10</div> */}
              <div className="stake-system"></div>
              <p className="card-copy">Stake Systems</p>
            </a>

            <a
              className="dv-card"
              href="https://example.com/hexxagon"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <div className="num-plate">11</div> */}
              <div className="hexxagon"></div>
              <p className="card-copy">Hexxagon</p>
            </a>
            {/* 
            <HoverCard
              numPlate="01"
              link="https://www.terrarium.com/"
              // imgDefault={StakebinLogoBlue}
              // imgHover={StakebinLogoWhite}
              // altText="Stakebin"
              // cardText="Stakebin"
            />
            <HoverCard
              numPlate="02"
              link="https://www.terrarium.com/"
              imgDefault={DelightLogoBlue}
              imgHover={DelightLogoWhite}
              altText="Delight Labs"
              cardText="Delight Labs"
            />
            <HoverCard
              numPlate="03"
              link="https://www.terrarium.com/"
              imgDefault={TerrariumBlue}
              imgHover={TerrariumWhite}
              altText="Terrarium"
              cardText="Terrarium"
            />
            <HoverCard
              numPlate="04"
              link="https://www.terrarium.com/"
              imgDefault={TerraportBlue}
              imgHover={TerraportGray}
              altText="Terraport"
              cardText="Terraport"
            />
            <HoverCard
              numPlate="05"
              link="https://www.terrarium.com/"
              imgDefault={NodeOpsBlue}
              imgHover={NodeOpsGray}
              altText="NodeOps"
              cardText="NodeOps"
            />
            <HoverCard
              numPlate="06"
              link="https://www.terrarium.com/"
              imgDefault={BurnItAllBlue}
              imgHover={BurnItAllGray}
              altText="Burn It All"
              cardText="Burn It All"
            />
            <HoverCard
              numPlate="07"
              link="https://www.terrarium.com/"
              imgDefault={TCBBlue}
              imgHover={TCBGray}
              altText="TCB"
              cardText="TCB"
            />
            <HoverCard
              numPlate="08"
              link="https://www.terrarium.com/"
              imgDefault={VegasNonde}
              imgHover={VegasOld}
              altText="Vegas Node"
              cardText="Vegas Node"
            />
            <HoverCard
              numPlate="09"
              link="https://www.terrarium.com/"
              imgDefault={LuncBlue}
              imgHover={LuncGray}
              altText="Lunc Goblins"
              cardText="Lunc Goblins"
            />
            <HoverCard
              numPlate="10"
              link="https://www.terrarium.com/"
              imgDefault={StakeSystemBlue}
              imgHover={StakeSystemwhite}
              altText="Stake Systems"
              cardText="Stake Systems"
            />
            <HoverCard
              numPlate="11"
              link="https://www.terrarium.com/"
              imgDefault={HexxagonBlue}
              imgHover={HexxagonWhite}
              altText="Hexxagon"
              cardText="Hexxagon"
            /> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default DelegationProgram;
